import { Divider } from "antd";
import React from "react";
import { Table } from "react-bootstrap";
import "./printPay.css";
const StaffPayDetailPrint = ({ reportData, icon, unitName }) => {
  const dateChange = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };
  return (
    <>
      {reportData?.map((item) => (
        <>
          <Divider />
          <div className="p-3" style={{ display: "flex" }}>
            <img style={{ height: 100, width: 110 }} src={icon} />

            <div className="col-md-6" style={{ paddingTop: "25px" }}>
              <h1>{unitName}</h1>
              <h5>Staff Pay Detail</h5>
            </div>
          </div>
          <div>
            <div className="d-flex justify-content-between col-md-10">
              <div className="d-flex">
                <h5>Staff Code: </h5> &nbsp;&nbsp; <h6>{item.emp_code}</h6>
              </div>
              <div className="d-flex">
                <h5>Name: </h5> &nbsp;&nbsp;<h6>{item.full_name}</h6>
              </div>
              <div className="d-flex">
                <h5>Account No: </h5> &nbsp;&nbsp; <h6>{item.account_no}</h6>
              </div>
            </div>

            <div className="d-flex justify-content-between col-md-10">
              <div className="d-flex">
                <h5>Joining Date:</h5> &nbsp;&nbsp; <h6>{item.joining_date}</h6>
              </div>
              <div className="d-flex">
                <h5>Designation: </h5> &nbsp;&nbsp;
                <h6>{item.designation.name}</h6>
              </div>
              <div className="d-flex">
                <h5>Campus: </h5> &nbsp;&nbsp; <h6>{item.campus.name}</h6>
              </div>
            </div>

            <div id="tableboot">
              <table className="tabler mt-5">
                <thead>
                  <tr>
                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                    >
                      <p className="">
                        <b>Month</b>
                      </p>
                    </td>
                    <td
                      className="text-center hereLineheighter rotateIT"
                      style={{ border: "1px solid black" }}
                    >
                      <p className="">
                        <b>Salary Days</b>
                      </p>
                    </td>
                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                    >
                      <p className="">
                        <b>Bank</b>
                      </p>
                    </td>
                    <td
                      className="text-center hereLineheighter rotateIT"
                      style={{ border: "1px solid black" }}
                    >
                      <p className="">
                        <b>Pay Scale</b>
                      </p>
                    </td>
                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                    >
                      <p>
                        <b>Basic Pay</b>{" "}
                      </p>
                      <p>
                        <b>Increment Total</b>
                      </p>{" "}
                    </td>
                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                    >
                      <p>
                        <b>Conv Allow</b>
                      </p>{" "}
                      <br />{" "}
                      <p>
                        <b>Science Allow</b>
                      </p>
                    </td>
                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                    >
                      <p>
                        <b>UGS Allow</b>{" "}
                      </p>
                      <p>
                        <b>Extra CA</b>
                      </p>{" "}
                    </td>
                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                    >
                      <p>
                        <b>Additional Allow</b>
                      </p>
                      <p>
                        <b>Extra Period Allow</b>
                      </p>
                    </td>
                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                    >
                      <p>
                        <b>2nd Shift Allow</b>
                      </p>{" "}
                      <p>
                        <b>HOD Allow</b>
                      </p>
                    </td>
                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                    >
                      <p>
                        <b>College Allow</b>
                      </p>{" "}
                      <p>
                        <b>Hostel Allow</b>
                      </p>{" "}
                    </td>
                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                    >
                      <p>
                        <b>Other Allow</b>
                      </p>{" "}
                      <p>
                        <b>Hifz Allow</b>
                      </p>{" "}
                    </td>
                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                    >
                      <b>GPF Return</b>
                    </td>

                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                    >
                      <p>
                        <b>Gross Salary</b>
                      </p>
                    </td>
                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                      colSpan={5}
                    >
                      <b>Deduction</b>
                      <td
                        className="text-center hereLineheighter"
                        style={{
                          borderRight: "1px solid black",
                          borderTop: "1px solid black",
                          width: "50px",
                        }}
                      >
                        <p>
                          <b>GPF</b>
                        </p>{" "}
                        <p>
                          <b>Insu Dedu</b>
                        </p>{" "}
                      </td>
                      <td
                        className="text-center hereLineheighter"
                        style={{
                          borderRight: "1px solid black",
                          borderTop: "1px solid black",
                          width: "50px",
                        }}
                      >
                        <p>
                          <b>I-Tax</b>
                        </p>{" "}
                        <p>
                          <b>Staff Van Dedu</b>
                        </p>{" "}
                      </td>
                      <td
                        className="text-center hereLineheighter"
                        style={{
                          borderRight: "1px solid black",
                          borderTop: "1px solid black",
                          width: "50px",
                        }}
                      >
                        <p>
                          <b>EOBI</b>
                        </p>{" "}
                        <p>
                          <b>Child Fee Dedu</b>
                        </p>
                      </td>
                      <td
                        className="text-center hereLineheighter"
                        style={{
                          borderTop: "1px solid black",
                          borderRight: "1px solid black",
                          width: "50px",
                        }}
                      >
                        <p>
                          <b>EOBI Pay</b>
                        </p>{" "}
                        <p>
                          <b>WF</b>
                        </p>
                      </td>
                      <td
                        className="text-center hereLineheighter"
                        style={{ borderTop: "1px solid black", width: "50px" }}
                      >
                        <p>
                          <b>Other Deduc</b>
                        </p>{" "}
                        <p>
                          <b>Loan Refun</b>
                        </p>
                      </td>
                    </td>
                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                    >
                      <b>Net Pay</b>
                    </td>
                    <td
                      className="text-center hereLineheighter"
                      style={{ border: "1px solid black" }}
                    >
                      <b>Voucher</b>
                    </td>
                  </tr>
                </thead>

                <tbody>
                  {item.employee_salary.map((subitem, index) => (
                    <tr key={index} id="tbl">
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {dateChange(subitem.salary_month)}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.days}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem?.bank_account?.bank_name && (
                          <p>{subitem.bank_account.bank_name.split("(")[0]}</p>
                        )}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {item.pay_scale.payscale}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.basic_pay} <br /> {subitem.increment}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.convance} <br /> {subitem.science}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.ugs} <br /> {subitem.extra_coaching}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.additional_allowance} <br />{" "}
                        {subitem.extra_period}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.second_shift} <br /> {subitem.hod}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.college} <br /> {subitem.hostel}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.other_allowance} <br /> {subitem.hifz}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.gpf_return} <br /> {subitem.gpf_cont}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {Number(subitem.gross_salary)}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          width: "50px",
                        }}
                        className="text_align_center"
                      >
                        {subitem.gp_fund} <br /> {subitem.insurance}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          width: "50px",
                        }}
                        className="text_align_center"
                      >
                        {subitem.income_tax} <br /> {subitem.van_charge}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          width: "50px",
                        }}
                        className="text_align_center"
                      >
                        {subitem.eobi} <br /> {subitem.child_fee_deduction}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          width: "50px",
                        }}
                        className="text_align_center"
                      >
                        {subitem.eobi_payments} <br /> {subitem.welfare_fund}
                      </td>
                      <td
                        style={{
                          color: "black",
                          border: "1px solid black",
                          width: "50px",
                        }}
                        className="text_align_center"
                      >
                        {subitem.other_deduction} <br /> {subitem.loan_refund}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {Number(subitem.net_pay)}
                      </td>
                      <td
                        style={{ color: "black", border: "1px solid black" }}
                        className="text_align_center"
                      >
                        {subitem.voucher?.voucher_no}
                      </td>
                    </tr>
                  ))}
                  <tr id="tbl">
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                        fontSize: "1.2rem",
                      }}
                      className="text_align_center text-primary"
                    >
                      Totals
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {"-"}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {"-"}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {"-"}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.basic_pay),
                        0
                      )}
                      <br />{" "}
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.increment),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.convance),
                        0
                      )}{" "}
                      <br />{" "}
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.science),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.ugs),
                        0
                      )}{" "}
                      <br />{" "}
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.extra_coaching),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.additional_allowance),
                        0
                      )}{" "}
                      <br />{" "}
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.extra_period),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.second_shift),
                        0
                      )}{" "}
                      <br />{" "}
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.hod),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.college),
                        0
                      )}{" "}
                      <br />{" "}
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.hostel),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.other_allowance),
                        0
                      )}{" "}
                      <br />{" "}
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.hifz),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.gpf_return),
                        0
                      )}
                      <br />
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.gpf_cont),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.gross_salary),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.gp_fund),
                        0
                      )}{" "}
                      <br />{" "}
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.insurance),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.income_tax),
                        0
                      )}{" "}
                      <br />{" "}
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.van_charge),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.eobi),
                        0
                      )}{" "}
                      <br />{" "}
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.child_fee_deduction),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.eobi_payments),
                        0
                      )}{" "}
                      <br />{" "}
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.welfare_fund),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.other_deduction),
                        0
                      )}{" "}
                      <br />{" "}
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.loan_refund),
                        0
                      )}
                    </td>

                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {item.employee_salary.reduce(
                        (a, c) => a + parseInt(c.net_pay),
                        0
                      )}
                    </td>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "bold",
                        border: "1px solid black",
                      }}
                      className="text_align_center"
                    >
                      {"-"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default StaffPayDetailPrint;
