import React, { useRef } from "react";
import { Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";

const StaffPayDetailsExport = ({ reportData }) => {
  const tableRef = useRef(null);
  const dateChange = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const d = new Date(date);

    return `${monthNames[d.getMonth()]}-${d.getFullYear()}`;
  };
  return (
    <>
      <DownloadTableExcel
        filename="Staff Pay Detail"
        sheet="StaffPayDetail"
        currentTableRef={tableRef.current}
      >
        <button className="btn btn-primary mx-2"> Export </button>
      </DownloadTableExcel>

      <Table ref={tableRef} style={{ display: "none" }}>
        <thead>
          <tr id="tbl" style={{ position: "relative" }}>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            {/* <th className="text-center"></th> */}
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
            <th className="text-center"></th>
          </tr>
          <tr
            id="tbl"
            style={{
              position: "sticky",
              top: "0",
              zIndex: "2",
              backgroundColor: "gainsboro",
            }}
          >
            <th className="text-center">Month</th>
            <th className="text-center">Salary Days</th>
            <th className="text-center">Bank</th>
            <th className="text-center">Pay Scale</th>
            <th className="text-center">Basic Pay</th>
            <th className="text-center">Voucher</th>
            <th className="text-center">Increment Total</th>
            <th className="text-center">Eobi</th>
            <th className="text-center">Eobi Payment</th>
            <th className="text-center">UGS Allowance</th>
            <th className="text-center">Additional Allowance</th>
            <th className="text-center">Extra Period Allowance</th>
            <th className="text-center">Extra Coaching</th>
            <th className="text-center">2nd Shift Allowance</th>
            <th className="text-center">HOD Allowance</th>
            <th className="text-center">College Allowance</th>
            <th className="text-center">Hostel Allowance</th>
            <th className="text-center">Hifz Allowance</th>
            <th className="text-center">Other Allowance</th>
            <th className="text-center">Conv Allow</th>
            <th className="text-center">Science</th>
            <th className="text-center">GPF Return</th>
            {/* <th className="text-center">GP Contribution</th> */}
            <th
              className="text-center"
              style={{
                borderLeft: "1px solid black",
                borderRight: "1px solid black",
              }}
            >
              Gross Salary
            </th>
            <th className="text-center">GP Fund</th>
            <th className="text-center">Loan Refund</th>
            <th className="text-center">Insu Deduction</th>
            <th className="text-center">Income Tax</th>
            <th className="text-center">Staff Van Deduction</th>
            <th className="text-center">Child Fee Deduction</th>
            <th className="text-center">Welfare Fund</th>
            <th className="text-center">Other Deduction</th>
            <th className="text-center">Net Salary</th>
          </tr>
        </thead>

        <tbody>
          {reportData.map((item, index) => (
            <React.Fragment key={index}>
              {item.employee_salary.map((subitem, subindex) => (
                <tr key={subindex} id="tbl">
                  <td style={{ color: "black" }} className="text_align_center">
                    {dateChange(subitem?.salary_month)}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.days}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.bank_account?.bank_name} (
                    {subitem?.bank_account?.account_head})
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.pay_scale == null
                      ? item?.pay_scale?.payscale
                      : subitem?.pay_scale?.payscale}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.basic_pay}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.voucher?.voucher_no}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.increment}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.eobi}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.eobi_payments}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.ugs}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.additional_allowance}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.extra_period}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.extra_coaching}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.second_shift}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.hod}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.college}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.hostel}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.hifz}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.other_allowance}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.convance}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.science}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.gpf_return}
                  </td>
                  {/* <td
                                style={{ color: "black" }}
                                className="text_align_center"
                              >
                                {subitem?.gpf_cont}
                              </td> */}
                  <td
                    style={{
                      color: "black",
                      borderLeft: "1px solid black",
                      borderRight: "1px solid black",
                      backgroundColor: "beige",
                    }}
                    className="text_align_center"
                  >
                    {Number(subitem?.gross_salary)}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.gp_fund}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.loan_refund}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.insurance}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.income_tax}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.van_charge}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.child_fee_deduction}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.welfare_fund}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {subitem?.other_deduction}
                  </td>
                  <td style={{ color: "black" }} className="text_align_center">
                    {Number(subitem?.net_pay)}
                  </td>
                </tr>
              ))}
              <tr
                id="tbl"
                style={{
                  borderTop: "2px solid black",
                  borderBottom: "2px solid black",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                <td
                  style={{ fontSize: "1.2rem" }}
                  className="text_align_center text-primary"
                >
                  Totals
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {"-"}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {"-"}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {"-"}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.basic_pay),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {"-"}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.increment),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.eobi),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.eobi_payments),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.ugs),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.additional_allowance),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.extra_period),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.extra_coaching),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.second_shift),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.hod),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.college),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.hostel),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.hifz),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.other_allowance),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.convance),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.science),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.gpf_return),
                    0
                  )}
                </td>

                {/* <td
                              style={{ color: "black" }}
                              className="text_align_center"
                            >
                              {item.employee_salary.reduce(
                                (a, c) => a + parseInt(c.gpf_cont),
                                0
                              )}
                            </td> */}
                <td
                  style={{
                    color: "black",
                    borderLeft: "1px solid black",
                    borderRight: "1px solid black",
                    backgroundColor: "beige",
                  }}
                  className="text_align_center"
                >
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.gross_salary),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.gp_fund),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.loan_refund),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.insurance),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.income_tax),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.van_charge),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.child_fee_deduction),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.welfare_fund),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.other_deduction),
                    0
                  )}
                </td>
                <td style={{ color: "black" }} className="text_align_center">
                  {item.employee_salary.reduce(
                    (a, c) => a + parseInt(c.net_pay),
                    0
                  )}
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default StaffPayDetailsExport;
